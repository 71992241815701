import './Pricing.css'
import Faq from '../../components/Faq/Faq';

function Pricing() {

    const plans = [
        {
            name: "Le One Page",
            desc: "Idéal pour avoir une présence rapidement sur internet et tester l’attraction de votre activité",
            price: 695,
         //   promoPrice: 295,
            isMostPop: false,
            features: [
                "Création d’une page",
                "Intégration du contenu",
                "SEO intégré",
                "Site au format responsive",
                "Formation de 1h pour la prise en main",
                "Maintenance technique pendant 1 mois",

            ],
        },
        {
            name: "Le Site Vitrine",
            desc: "Créer une identité et une présence forte grâce à un site présentant vos services ainsi que votre entreprise.",
            price: 995,
            isMostPop: true,
            features: [
                "Création d’une page d’accueil et 3 pages",
                "Intégration du contenu",
                "SEO intégré",
                "Site au format responsive",
                "Formation de 1h pour la prise en main",
                "Maintenance technique pendant 3 mois",
            ],
        },
        {
            name: "Le Sur-Mesure",
            desc: "Une identité FORTE et un site avec des fonctionnalités avancées : e-commerce, réservation, ou vendre des cours ou des abonnements.",
            price: "à partir de 1895",
            isMostPop: false,
            features: [
                "Création d’une page d’accueil et 4 pages",
                "Identité de marque",
                "Intégration des fonctionnalités",
                "Intégration du contenu",
                "SEO intégré",
                "Site au format responsive",
                "Formation de 1h pour la prise en main",
                "Maintenance technique pendant 3 mois",
            ],
        },
    ];

    return (
        <section id="pricing" className='py-14 bg-[#111]'>
            <div className="max-w-screen-xl mx-auto px-4 text-gray-600 md:px-8">
                <div className='relative max-w-xl mx-auto sm:text-center'>
                <h3 className='text-4xl text-[#f4f3f3] font-extrabold mx-auto md:text-5xl'>
                    Tarifs & prestations
                </h3>
                <div className='mt-3 max-w-xl'>
                    <p className='text-[#f4f3f3]'>
                        3 formats différents en fonction du budget et des fonctionnalités, pour un site avec une identité graphique moderne, qui vous représente et vous aident à atteindre vos objectifs.     
                    </p>
                    <p className='text-[--slate-800]'>
                    *Tarifs d'applications mobile sur devis.
                    </p>
                </div>
                </div>
                <div className='mt-16 justify-center gap-6 sm:grid sm:grid-cols-2 sm:space-y-0 lg:grid-cols-3 shadow-card'>
                    {
                        plans.map((item, idx) => (
                            <div key={idx} className={`relative flex-1 flex items-stretch flex-col bg-[#f4f3f3] rounded-xl border-2 mt-6 sm:mt-0 ${item.isMostPop ? "mt-10" : ""}`}>
                                {
                                    item.isMostPop ? (
                                        <span className="w-32 absolute -top-5 left-0 right-0 mx-auto px-3 py-2 rounded-full bg-custom-gradient shadow-md text-center text-[#f4f3f3] text-sm font-bold">Populaire</span>
                                    ) : ""
                                }
                                <div className="p-8 space-y-4 border-b">
                                    <span className='text-gray- font-bold'>
                                        {item.name}
                                    </span>
                                    <div className='text-gray-700 text-3xl font-semibold'>
    {item.promoPrice ? (
        <>
            <span className="line-through text-gray-400 mr-2">{item.price}€ TTC</span>
            <span>{item.promoPrice}€ TTC </span>
            <div><i className="fa-solid fa-bullhorn text-2xl mr-2"></i> Offre spécial ÉTÉ</div>        

        </>
    ) : (
        `${item.price}€ TTC`
    )}
</div>
                                    <p>
                                        {item.desc}
                                    </p>
                                </div>
                                <ul className='p-8 space-y-3'>
                                    {
                                        item.features.map((featureItem, idx) => (
                                            <li key={idx} className='flex items-center gap-5'>
                                                <svg
                                                    xmlns='http://www.w3.org/2000/svg'
                                                    className='h-5 w-5 text-[--slate-800]'
                                                    viewBox='0 0 20 20'
                                                    fill='currentColor'>
                                                    <path
                                                        fillRule='evenodd'
                                                        d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
                                                        clipRule='evenodd'></path>
                                                </svg>
                                                {featureItem}
                                            </li>
                                        ))
                                    }
                          <a href="https://calendly.com/atelierdecember/appel-de-decouverte-pour-la-creation-de-site-web" target="_blank" rel="noopener noreferrer">
                            <button className='mt-10 px-3 py-3 rounded-lg w-full font-bold text-sm duration-150 text-[#f4f3f3] bg-custom-gradient hover:px-6 hover:opacity-80 hover:text-[#f4f3f3] shadow-card'>
                                Recevoir un devis gratuit
                            </button>
                        </a>

                                </ul>
                            </div>
                        ))
                    }
                </div>
            </div>
            <Faq/>
        </section>
        
    );
};
  
  export default Pricing;