import React from 'react';
import Image from '../../img/Silent Developer at Work.jpg'
import './About.css';



   function About() {

    const teams = [
        {
            icon:
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
            </svg>,
        title: "Responsive",
        desc: "À la livraison, votre site est responsive, c'est à dire qu'il fonctionne sur toutes les tailles d'écrans."
        },
        {
            icon:
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />
                </svg>,
            title: "Sécurité avancée",
            desc: "Nous veillons à toujours utilisé les meilleurs pratiques en ce qui concerne la sécurité de votre projet."
        }
    ]

    return (
        <section className="py-14 bg-[#111]">
            <div className="max-w-screen-xl mx-auto px-4 text-gray-600 gap-16 justify-between md:px-8 lg:flex">
                <div>
                    <div className="max-w-xl text-[#f4f3f3] space-y-3">
                        <p className=" text-3xl font-semibold sm:text-4xl">
                        L'Agence Atelier December
                        </p>
                        <p>
                        Spécialisée dans la création de sites web sur mesure et le marketing digital. Grâce à son expertise multiple, notre équipe vous accompagnes dans la réalisation de vos projets.                        </p>
                    </div>
                    <div className="mt-12 max-w-lg lg:max-w-none">
                        <ul className="space-y-8">
                            {
                                teams.map((item, idx) => (
                                    <li key={idx} className="flex gap-x-4">
                                        <div className="flex-none w-12 h-12 bg-custom-gradient text-[#f3f4f4] rounded-lg flex items-center justify-center">
                                            {item.icon}
                                        </div>
                                        <div>
                                            <h4 className="text-lg text-[#f4f3f3] font-semibold">
                                                {item.title}
                                            </h4>
                                            <p className="mt-3 text-[#f4f3f3]">
                                                {item.desc}
                                            </p>
                                        </div>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                </div>
                <div className="mt-12 lg:mt-0">
                <img src={Image} alt="" className="rounded-xl"/>
            </div>
            </div>
        </section>
  )
}

export default About;